import Button from 'components/Button/Button';
import Label from 'components/Label/Label';
import { useCharter } from 'hooks';
import { useTranslation } from 'react-i18next';

export default function CharterChoiceList() {
  const { t } = useTranslation();
  const { charters, charter: currentCharter, changeCharter } = useCharter();

  return (
    <div className="flex flex-col items-center pt-2 md:pt-0 pb-6">
      {charters.map((charter) => {
        const pending = charter.status === 'PENDING';

        return (
          <div key={charter.id} className="flex items-center gap-x-2 mt-2">
            <Button
              variant="link"
              ring={charter === currentCharter}
              onClick={() => changeCharter(charter)}
              disabled={pending}
            >
              {charter.name}
            </Button>

            {pending && <Label blue>{t('Pending')}</Label>}
          </div>
        );
      })}
    </div>
  );
}
