import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n from 'i18n';
import { Locale } from 'i18n/types';
import {
  changeDayjsLocale,
  detectLocaleFromPath,
  languageCode,
} from 'utils/i18n';

type LocaleState = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

type LocaleProviderProps = {
  children: ReactNode;
  forcedLocale?: Locale;
};

const LocaleContext = createContext<LocaleState | undefined>(undefined);

let globalLocale: Locale;

function LocaleProvider({ children, forcedLocale }: LocaleProviderProps) {
  const [locale] = useState(forcedLocale || detectLocaleFromPath());

  useEffect(() => {
    // https://github.com/facebook/react/issues/14326#issuecomment-441680293
    (async () => {
      // Save outside context to be able use it outside components (eg. http requests)
      globalLocale = locale;

      document.documentElement.lang = locale; // Set <html lang> attribute

      // Firstly load locale for dayjs (it's not reactive),
      // App will be rerendered by i18next below
      await changeDayjsLocale(locale);

      i18n.changeLanguage(locale);
    })();
  }, [locale]);

  // Refresh page after changing locale to fix google maps package loading
  // problem - re-render causes reloading of the package which is prohibited
  // TODO: possible solutions: 1. use react components instead of vanilla JS
  // 2. find different google maps package, 3. use google maps script directly
  const setLocale = useCallback((locale: Locale) => {
    const { pathname, search, hash } = window.location;
    const url =
      '/' + languageCode(locale) + pathname.substring(3) + search + hash;

    window.location.assign(url);
  }, []);

  const value = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

function getLocale() {
  return globalLocale;
}

export { LocaleContext, LocaleProvider, getLocale };
